module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-snipcart-advanced/gatsby-browser.js'),
      options: {"plugins":[],"version":"3.0.29","publicApiKey":"OTUzMGIzYTMtZWJiYi00Y2Q1LThmNGYtMWE5NWIzNjJlOGMyNjM3ODcyNzk5NTE4NDc2OTY4","defaultLang":"en","currency":"usd","openCartOnAdd":true,"useSideCart":true,"innerHTML":"\n          <billing section=\"bottom\">\n              <fieldset class=\"snipcart-form__set\">\n                  <div class=\"snipcart-form__field\">\n                      <snipcart-label for=\"organization\">\n                          School/Business\n                      </snipcart-label>\n                      <snipcart-input name=\"organization\"></snipcart-input>\n                  </div>\n              </fieldset>\n          </billing>"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
